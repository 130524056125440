var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("footer", { staticClass: "footer" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("nav", { staticClass: "pull-left" }, [
          _c("ul", [
            _c("li", [
              _c("a", { attrs: { href: "http://www.creative-tim.com" } }, [
                _vm._v("\n            Creative Tim\n          "),
              ]),
            ]),
            _c("li", [
              _c("a", { attrs: { href: "http://blog.creative-tim.com" } }, [
                _vm._v("\n            Blog\n          "),
              ]),
            ]),
            _c("li", [
              _c(
                "a",
                { attrs: { href: "http://www.creative-tim.com/license" } },
                [_vm._v("\n            Licenses\n          ")]
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "copyright pull-right" }, [
          _vm._v("\n      © Coded with\n      "),
          _c("i", { staticClass: "fa fa-heart heart" }),
          _vm._v(" by\n      "),
          _c(
            "a",
            {
              attrs: {
                href: "https://github.com/cristijora",
                target: "_blank",
              },
            },
            [_vm._v("Cristi Jora")]
          ),
          _vm._v(".\n      "),
          _c("span", [
            _vm._v("Designed by "),
            _c(
              "a",
              {
                attrs: {
                  href: "https://www.creative-tim.com/?ref=pdf-vuejs",
                  target: "_blank",
                },
              },
              [_vm._v("Creative Tim")]
            ),
            _vm._v("."),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }