<template>
  <div class="wrapper">
    <side-bar type="sidebar" :sidebar-links="$sidebar.sidebarLinks">
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>
      <dashboard-content @click.native="toggleSidebar">
      </dashboard-content>
      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
    @import 'src/assets/sass/etl-dashboard.scss';
</style>
<script>
import Vue from 'vue'
import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import DashboardContent from './Content.vue'
//import VueElementLoading from 'vue-element-loading'

import initPluginViewMixin from '../mixins/initPluginViewMixin'
//import initPlugin from '../initPlugin'
import draggable from "vuedraggable"


export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent
  },
  mixins:[initPluginViewMixin],
  data(){
    return {
      /*isLoading: false*/
    }
  },
  beforeCreate() {
    /*Vue.component('draggable', draggable)*/

    /*Vue.use(initPlugin,{
      appConfig: this.$appConfig,
      router: this.$router
    });*/
  },
  beforeMount() {
    this.$services['vueDfApplicationService'].loadAllRoutes(function (routes) {
    });
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    }
  }
}

</script>
