var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "navbar",
    {
      model: {
        value: _vm.showNavbar,
        callback: function ($$v) {
          _vm.showNavbar = $$v
        },
        expression: "showNavbar",
      },
    },
    [
      _c("div", { staticClass: "navbar-wrapper" }, [
        _c("div", { staticClass: "navbar-minimize" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-icon btn-round",
              attrs: { id: "minimizeSidebar" },
              on: { click: _vm.minimizeSidebar },
            },
            [
              _c("i", {
                staticClass:
                  "fas fa-chevron-right text-center visible-on-sidebar-mini",
              }),
              _c("i", {
                staticClass:
                  "fas fa-chevron-left text-center visible-on-sidebar-regular",
              }),
            ]
          ),
        ]),
        _c(
          "div",
          { staticClass: "navbar-toggle" },
          [
            _c("navbar-toggle-button", {
              nativeOn: {
                click: function ($event) {
                  return _vm.toggleSidebar.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
        _c("a", { staticClass: "navbar-brand", attrs: { href: "#" } }, [
          _vm._v("ETL Pipeline Dashboard"),
        ]),
      ]),
      _c("template", { slot: "navbar-menu" }, [
        _c("form", [
          _c("div", { staticClass: "input-group no-border" }, [
            _c("input", {
              staticClass: "form-control",
              attrs: { type: "text", value: "", placeholder: "Search..." },
            }),
            _c("div", { staticClass: "input-group-append" }, [
              _c("div", { staticClass: "input-group-text" }, [
                _c("i", { staticClass: "nc-icon nc-zoom-split" }),
              ]),
            ]),
          ]),
        ]),
        _c("ul", { staticClass: "navbar-nav" }, [
          _c("li", { staticClass: "nav-item" }, [_c("user-info-component")], 1),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }